.privacy-policy-sec{
 padding-top: 6em;
}
.static-page-card{
  background:  #202235;
  padding: 4em;
  border-radius:12px;
  border: 1px solid rgba(157, 157, 157, 0.25);
}

.static-page-card{
 display: flex;
 flex-direction: column;
 gap: 1em;
}

.static-page-card h4{
  font-weight: 600;
  color: var(--secondary-color);
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 0.3em;
}

.static-page-card p{
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0;
}

.static-page-card ul li{
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0;
}

.contact-us-sec{
  padding-top: 6em;
}

.contact-us-card{
  background: linear-gradient(104.67deg, rgba(21, 25, 52, 0.75) 0%, rgba(42, 28, 53, 0.743) 100%);
  padding: 2em;
  border-radius: 2em;
  border: 1px solid rgba(157, 157, 157, 0.25);
}

.contact-us-info{
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
}

.contact-us-info p{
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0;
}

.contact-us-form .form-label{
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0.5em;
}

.contact-us-form .form-control {
  background: linear-gradient(287.42deg, rgba(222, 218, 218, 0.441) 4.56%, rgba(202, 198, 198, 0.35) 95.51%);
  border: none;
  color: var(--secondary-color) !important;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0;
}

.contact-us-form .form-control:focus{
  background: linear-gradient(287.42deg, rgba(222, 218, 218, 0.441) 4.56%, rgba(202, 198, 198, 0.35) 95.51%);
  border: none;
  box-shadow: none;
}

.contact-form-info{
  padding-bottom: 1em;
}

.contact-us-form .form-control::placeholder{
  font-weight: 400;
  color: #999;
  font-size: 0.9em;
}

.contact-form-info h4{
  font-weight: 600;
  color: var(--secondary-color);
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 0.3em;
}

.contact-form-info p{
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.9em;
  line-height: 1.8;
  margin-bottom: 0;
}

.contact-us-form .contact-form-submit-btn{
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
  padding: 0.4em 1em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%);
  color: var(--tertiary-color) !important;
  display: inline-block;
  border: none;
  font-size: 0.9em;
  font-weight: 600;
  background-size: 150% 100%;
  -webkit-transition: all .3s cubic-bezier(.02, .54, .58, 1);
  -o-transition: all .3s cubic-bezier(.02, .54, .58, 1);
  transition: all .3s;
  margin-top: 1em;
}

.contact-us-form .contact-form-submit-btn:hover{
  background-color: var(--secondary-color);
  color: var(--tertiary-color) !important;
  border-color: var(--primary-color)!important;
}

.contact-us-form .contact-form-submit-btn:focus,
.contact-us-form .contact-form-submit-btn:active{
  background-color: var(--secondary-color);
  color: var(--tertiary-color) !important;
  border-color: var(--primary-color)!important;
}

.about-us-sec{
  padding-top: 6em;
}

.about-us-banner-img{
  display: flex;
  justify-content: flex-start;
}

.about-us-banner-info{
  display: flex;
  flex-direction: column;
  gap: 1em;
 }
 
 .about-us-banner-info h3{
   font-weight: 600;
   color: var(--secondary-color);
   font-size: 1.2em;
   line-height: 1.5;
   margin-bottom: 0.3em;
 }
 
 .about-us-banner-info p{
   font-weight: 400;
   color: var(--quaternary-color);
   font-size: 0.9em;
   line-height: 1.8;
   margin-bottom: 0;
 }

 .our-vision{
   padding-top: 4em;
 }

@media (max-width: 575.98px) {
  .static-page-card{
    background:  #202235;
    padding: 1em;
    border-radius:6px;
  }
  .contact-us-card{
    padding: 1em;
    border-radius: 6px;
  }
  .contact-us-form{
    padding: 0 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .static-page-card{
    background:  #202235;
    padding: 1em;
    border-radius:6px;
  }
  .contact-us-card{
    padding: 1em;
    border-radius: 6px;
  }
  .contact-us-form{
    padding: 0 0;
  }
}